<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Name the following molecules, specifying isomers with the E/Z system:</p>

      <table>
        <thead>
          <tr>
            <th>Molecule</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <v-img :src="imageOne" style="max-width: 200px" />
            </td>
            <td>
              <s-textarea v-model="inputs.input1" outlined />
            </td>
          </tr>

          <tr>
            <td>
              <v-img :src="imageTwo" style="max-width: 200px" />
            </td>
            <td>
              <s-textarea v-model="inputs.input2" outlined />
            </td>
          </tr>

          <tr>
            <td>
              <v-img :src="imageThree" style="max-width: 200px" />
            </td>
            <td>
              <s-textarea v-model="inputs.input3" outlined />
            </td>
          </tr>

          <tr>
            <td>
              <v-img :src="imageFour" style="max-width: 200px" />
            </td>
            <td>
              <s-textarea v-model="inputs.input4" outlined />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'ChemLC241PS9Q2',
  components: {
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
    };
  },
  computed: {
    imageOne() {
      return '/img/assignments/LC241/q2-img1.png';
    },
    imageTwo() {
      return '/img/assignments/LC241/q2-img2.png';
    },
    imageThree() {
      return '/img/assignments/LC241/q2-img3.png';
    },
    imageFour() {
      return '/img/assignments/LC241/q2-img4.png';
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
